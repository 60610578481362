import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";
import {
  FaChevronRight,
  FaEnvelope,
  FaGlobe,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import "./style.css";

const override = {
  // display: "block",
  // margin: "0 auto",
  width: "60%",
  borderColor: "#6fe7dd",
};

const Ready = () => {

  const [requestPrompt, setRequestPrompt] = useState('');
  const [loading, setLoading] = useState(true);
  const spinnerColour = '#6fe7dd';

  const submitBuildIt = () => {
    console.log(requestPrompt)
    setLoading(true)
  }

  if (loading) {
    return (
      <>
        <section className="contact-form section_100">
          <Container>
            <Row>
              <Col lg={12}>
              <BarLoader
                color={spinnerColour}
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="section-title  ">
                  <h2>
                    <span>Your</span> Software is ready.
                  </h2>
                  <br /><br/>
                  Open it <a target="_blank" href="https://jobs11111.fly.dev/">here</a> 
                </div>
              </Col>
            </Row>          
          </Container>
        </section>
      
      </>
    )
  }

  return (
    <>

      {/* Contact Form Start */}
      <section className="contact-form section_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title  ">
                <h2>
                  Build your <span>software</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <div className="contact-form-wrapper ">
                <form>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group button-area">
                        <label htmlFor="message">Write your software description here. Check out our <a href="#">help page</a> to guide you.</label>
                        <textarea
                          id="message"
                          value={requestPrompt}
                          className="input-field textarea"
                          placeholder="Describe your software"
                          onChange={e => setRequestPrompt(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="form-group button-area">
                        <button type="button" className="theme-btn" onClick={submitBuildIt}>
                          Build it
                          <span>
                            <FaChevronRight />
                          </span>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Contact Form End */}

     


    </>
  );
};

export default Ready;
