import React from "react";
import Register from "../../components/Register";

const RegisterPages = () => {
  return (
    <>
      <Register />
    </>
  );
};
export default RegisterPages;
