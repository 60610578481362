import React from "react";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Contact from "../../components/Contact";
import Subscribe from "../../components/Subscribe";
import Footer from "../../components/Footer";
import Logo from "../../img/logo-white.png";
import Build from './build';

const BuildPage = () => {
  return (
    <>
      <Header Logo={Logo} />
      <PageTitle
        // PageTitle={
        //   <div>
        //     <span>Build it</span>
        //   </div>
        // }
      />
      <Build />
      <Footer />
    </>
  );
};
export default BuildPage;
